@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    height: 100vh;
    width: 100vw;
    background-color: #e9f1fa;
}

#root{
    width: 100vw;
    height: 100vh;
}

.App{
    width: 100vw;
    height: 100vh;
}